import { makeStyles } from '@material-ui/core'
import React from 'react'
import Button from '../../../shared/components/atoms/Button/Button'
import SelectOutlinedMaterial from '../../../shared/components/atoms/select-outlined-material/select-outlined-material'
import MyRedemptionsItem from '../myRedemptionsItem/myRedemptionsItem'
import './myRedemptionsTable.scss'
import useHomeLinkMode from '../../../shared/hooks/useHomeLinkMode'
import { isAdviser } from '../../../shared/state/utils/isAdviser'
import SessionService from '../../../shared/services/session'
import { convertText } from '../../../shared/utils/convertFirstCharacterToUppercase';

const MyRedemptionsTable = ({ redemptions }) => {
	const [filteredDates] = React.useState(getCreationDates())
	const [activeTag, setActiveTag] = React.useState('Todas')
	const [filteredRedemptions, setFilteredRedemptions] = React.useState(
		redemptions
	)
	const { homeLink } = useHomeLinkMode()

	function getCreationDates() {
		const datesArray = ['Todas']
		redemptions.forEach(redemption => {
			const date = new Date(redemption.dateUpdate)
			const year = date.getFullYear().toString()
			if (!datesArray.includes(year)) {
				return datesArray.push(year)
			}
		})
		return datesArray
	}

	const useStyles = makeStyles(() => ({
		formControl: {
			margin: 0,
			minWidth: '170px',
		},
		label: {
			color: '#7996A8',
			fontSize: '1rem',
			marginLeft: 16,
			backgroundColor: 'white',
		},
		select: {
			color: '#7996A8',
			border: 'none',
		},
	}))
	const classes = useStyles()

	const handleFilterYears = _date => {
		const date = new Date(_date)
		return date.getFullYear().toString()
	}

	const handleChangeTag = _value => {
		setActiveTag(_value)
		if (_value.toLowerCase() === 'todas') {
			setFilteredRedemptions(redemptions)
		} else {
			const newRedemptions = redemptions.filter(
				redemption => handleFilterYears(redemption.dateUpdate) === _value
			)
			setFilteredRedemptions(newRedemptions)
		}
	}

	return (
		<>
			<div className='f-myredemption-section-filter'>
				<div className='f-filter-container'>
					<label className='f-p f-filter-title'>
						Filtra tus puntos
						<br />
					</label>
					<SelectOutlinedMaterial
						value={activeTag}
						setValue={handleChangeTag}
						styles={classes.formControl}
						styleLabel={classes.label}
						styleSelect={classes.select}
						name={'redemptionsTags'}
						label=''
						items={(filteredDates || []).map(date => ({
							key: date,
							value: date,
						}))}
					/>
				</div>

				{!isAdviser(SessionService.getIdTokenData()) && (
					<Button
						type='button'
						onClickAction={() => {
							window.location.href =
								homeLink + 'beneficios/redime-puntos/bonos-para-comprar-tena'
						}}
						classname='f-button f-button--primary'
						text={convertText('VER CATÁLOGO DE REDENCIONES')}
					/>
				)}
			</div>
			<div className='f-myredemption-table'>
				{filteredRedemptions.map((item, index) => {
					return <MyRedemptionsItem redemption={item} key={index} />
				})}
			</div>
		</>
	)
}

export default MyRedemptionsTable
