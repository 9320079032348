import { graphql } from 'gatsby'
import React from 'react'
import AccountScoreHeader from '../../components/molecules/accountScoreHeader/accountScoreHeader'
import MyRedemptionsSection from '../../components/molecules/myRedemptionsSection/myRedemptionsSection'
import TabProducts from '../../shared/components/molecules/tabProducts/tabProducts'
import Layout from '../../shared/components/organisms/layout/layout'
import SEO from '../../shared/components/seo'
import useBrowserMode from '../../shared/hooks/useBrowserMode'
import useHomeLinkMode from '../../shared/hooks/useHomeLinkMode'
import useLocaleMode from '../../shared/hooks/useLocaleMode'
import useMobileMode from '../../shared/hooks/useMobileMode'
import useSessionState from '../../shared/hooks/useSessionState'
import SessionService from '../../shared/services/session'
import SearchAdviser from '../../shared/components/atoms/search-adviser/search-adviser'

const MisRedenciones = props => {
	const { isMobile } = useMobileMode()
	const { locale } = useLocaleMode()
	const { isBrowser } = useBrowserMode()
	const { isEffect, actualStorage } = useSessionState()
	const { homeLink } = useHomeLinkMode()
	const points = isBrowser && SessionService.getUserPoints()
	let storageInfo
	if (isEffect) {
		storageInfo =
			actualStorage === 0
				? localStorage.getItem('userData')
				: sessionStorage.getItem('userData')
		if (!storageInfo) {
			isBrowser && (window.location.href = homeLink)
		}
	}

	return (
		<>
			{isBrowser && (
				<Layout title='MIS REDENCIONES' isMobile={isMobile}>
					<article>
						<>
							<SearchAdviser />
						</>
						<SEO lang={locale} />
						<AccountScoreHeader actualScore={points} locale={locale} />
						<TabProducts
							tabs={props.data.allContentfulTenaMenu.nodes[0]}
							slug={'/mi-cuenta/mis-redenciones'}
						/>
						<MyRedemptionsSection />
					</article>
				</Layout>
			)}
		</>
	)
}

export const query = graphql`
	query MY_ACCOUNT_REDEMPTIONS($language: String!) {
		allContentfulTenaMenu(
			filter: {
				node_locale: { eq: $language }
				titulo: { eq: "Menú > Mi Cuenta" }
			}
		) {
			nodes {
				opcionesMenu {
					nombre
					slug
					categoria {
						slug
					}
				}
			}
		}
	}
`

export default MisRedenciones
