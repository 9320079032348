import React from 'react'
import { MONTHS_SHORTNAME } from '../../../shared/state/constants/appConstants'
import './myRedemptionsItem.scss'
import { formatNumber } from '../../../shared/state/utils/utilities-format-number'

const MyRedemptionsItem = ({ redemption }) => {
	const [activeInfo, setActiveInfo] = React.useState(() => {
		return window.innerWidth >= 761
	})

	const formatDateForMyOrders = _date => {
		const date = new Date(_date)
		return _date === null
			? '-'
			: `${date.getDate()} / ${
					MONTHS_SHORTNAME[date.getMonth()]
			  } / ${date.getFullYear()}`
	}

	const infoShow = width => {
		if (width < 761) {
			if (activeInfo) {
				setActiveInfo(false)
			} else {
				setActiveInfo(true)
			}
		} else {
			setActiveInfo(true)
		}
	}

	return (
		<div className='f-myredemption-item'>
			<div
				className='f-myredemption-item-header'
				onClick={() => infoShow(window.innerWidth)}
			>
				<div className='f-myredemption-item-type'>
					<div className='f-myredemption-item-type-img'>
						<img alt={redemption.name} src={redemption.image} />
					</div>

					<div>
						<p className='f-myredemption-item-type-title'>{redemption.type}</p>
						<p className='f-myredemption-item-type-copy'>{redemption.name}</p>
					</div>
				</div>
				<div className='f-myredemption-item-state'>
					<p className='f-myredemption-title-generic'>ESTADO DE LA ORDEN</p>
					<p
						className={
							redemption.state === 'PENDIENTE'
								? 'f-myredemption-item-state-copy f-myredemption-item-state-copy-orange'
								: 'f-myredemption-item-state-copy'
						}
					>
						{redemption.state}
					</p>
				</div>
				<p className='f-myredemption-item-points'>
					{formatNumber(redemption.points)} Puntos
				</p>
			</div>
			{activeInfo && (
				<div className='f-myredemption-item-info'>
					<div className='f-myredemption-item-state-mobile'>
						<p className='f-myredemption-title-generic'>ESTADO DE LA ORDEN</p>
						<p className='f-myredemption-item-state-copy'>{redemption.state}</p>
					</div>
					<div>
						<p className='f-myredemption-title-generic'>NÚMERO DE ORDEN </p>
						<p className='f-myredemption-copy-generic'>
							{redemption.trackingId}
						</p>
					</div>
					<div>
						<p className='f-myredemption-title-generic'>FECHA REDENCIÓN </p>
						<p className='f-myredemption-copy-generic'>
							{formatDateForMyOrders(redemption.dateUpdate)}
						</p>
					</div>
					<div>
						<p className='f-myredemption-title-generic'>FECHA VENCIMIENTO </p>
						<p className='f-myredemption-copy-generic'>
							{formatDateForMyOrders(redemption.dateExpire)}
						</p>
					</div>
					<div>
						<p className='f-myredemption-title-generic'>CÓDIGO DIGITAL</p>
						<p className='f-myredemption-copy-generic'>{redemption.id}</p>
					</div>
				</div>
			)}
		</div>
	)
}

export default MyRedemptionsItem
