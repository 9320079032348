import React from 'react'
import './myRedemptionsSection.scss'
import BannerTitle from '../../atoms/banner-title/banner-title'
import MyRedemptionsTable from '../myRedemptionsTable/myRedemptionsTable'
import ProfileService from '../../../shared/services/profile'
import { successStatus } from '../../../shared/state/constants/appConstants'
import AdviserService from '../../../shared/services/adviser'
import { isAdviser } from '../../../shared/state/utils/isAdviser'
import SessionService from '../../../shared/services/session'

const MyRedemptionsSection = () => {
	const [redemptions, setRedemptions] = React.useState()
	React.useEffect(() => {
		if (isAdviser(SessionService.getIdTokenData())) {
			AdviserService.myRedemptions(
				restData => {
					const { data } = restData
					if (data.status === successStatus) {
						setRedemptions(data.data)
					}
				},
				error => {
					console.log(error)
				}
			)
		} else {
			ProfileService.myRedemptions(
				restData => {
					const { data } = restData
					if (data.status === successStatus) {
						setRedemptions(data.data)
					}
				},
				error => {
					console.log(error)
				}
			)
		}
	}, [])

	return (
		<section className='f-myredemption-section'>
			<BannerTitle
				title={'MIS REDENCIONES'}
				description={
					'Aquí encontrarás las redenciones realizadas de productos o bonos con tus puntos TENA'
				}
				paddingLeft={'6%'}
			/>
			{redemptions && <MyRedemptionsTable redemptions={redemptions} />}
		</section>
	)
}

export default MyRedemptionsSection
